import { API_RESPONSE, API_PREFIX } from './_const';
import moment from 'moment';
import { cartData, orderData } from './_mock';
import { address } from '../redux/slices/_address';
import { useSelector } from 'react-redux';
import store, { actions, getters } from '../redux/store';

const useAPIMethods = ({ axios }) => {
  const updateToken = (token, ocaToken, cookie, refreshToken) => {
    axios.updateToken(token, ocaToken, cookie, refreshToken);
  };

  const getSampleGet = (url) =>
    new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          console.log('response:' + JSON.stringify(response));

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getSamplePost = (url) =>
    new Promise((resolve, reject) => {
      console.log(url);
      axios
        .post(url)
        .then((response) => {
          console.log('response:' + JSON.stringify(response));

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getInfo = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-info`, {
          passLocationInfo: 'true',
          passShippingInfo: 'true',
        })
        .then((response) => {
          // console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getRefoundList = (
    startDate = '2020-01-01',
    endDate = '2020-12-31',
    currentPage = 1,
    perPage = 20
  ) =>
    new Promise((resolve, reject) => {
      console.log('requset page', currentPage);
      console.log('requset per_page', perPage);
      axios
        .get(
          `${API_PREFIX}/Api-getOrderReturn?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&per_page=${perPage}`
        )
        .then((response) => {
          // console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            alert(response.reason);
            //alert response.reason
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getRefundDetail = (shiprefund_code) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${API_PREFIX}/Api-getOrderReturnDetail?shiprefund_code=${shiprefund_code}`
        )
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};

            const products = {
              first: [],
              other_else: [],
            };

            let index = 0;
            for (const product of response.data.products) {
              if (index === 0) {
                products.first.push(product);
              } else {
                products.other_else.push(product);
              }
              index = index + 1;
            }
            response.data.products = products;

            resolve(response.data);
          } else {
            alert(response.reason);
            //alert response.reason
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCartList = (address) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getBasket?address=${address}`)
        .then((response) => {
          // console.log('response: ' + JSON.stringify(response));
          // response = cartData();
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            if (response.code === API_RESPONSE.STORE_CLOSE) {
              alert(response.reason, () => {
                resolve(false);
              });
              return;
            } else {
              alert(response.reason);
            }
            //alert response.reason
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const addCart = ({
    productId,
    quantity = 0,
    itemOutofstockAction = 1,
    currentStoreId = '',
    additionalItem = 0,
  }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        productId: productId,
        quantity: quantity,
        itemOutofstockAction: itemOutofstockAction,
        // storeId: currentStoreId || '',
        storeId: currentStoreId,
        additionalItem,
      };
      console.log(parameters);
      axios
        .post(`${API_PREFIX}/Api-addItemToBasket`, parameters)
        .then((response) => {
          // console.log('response: ' + JSON.stringify(response));
          // response = cartData();
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            alert(response.reason, () => {
              resolve(false);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const removeCart = ({ productId, currentStoreId = '', additionalItem = 0 }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        productId: productId,
        storeId: currentStoreId,
        additionalItem: additionalItem,
      };
      axios
        .post(`${API_PREFIX}/Api-removeItemFromBasket`, parameters)
        .then((response) => {
          // console.log('response: ' + JSON.stringify(response));
          // response = cartData();
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            //alert response.reason
            alert(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const updateCart = ({
    productId,
    quantity = 0,
    itemOutofstockAction = 1,
    currentStoreId = '',
    fromPLP = false,
  }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        productId: productId,
        quantity: quantity,
        itemOutofstockAction: itemOutofstockAction,
        storeId: currentStoreId,
      };
      axios
        .post(`${API_PREFIX}/Api-updItemInBasket`, parameters)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            if (fromPLP) {
              alert(response.reason);
              resolve(false);
            } else {
              alert(response.reason, () => {
                resolve(false);
              });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getStoreByLocation = (lat, lng, addr, model = 1) =>
    new Promise((resolve, reject) => {
      var parameters = {};
      if (lat !== null && lat !== undefined) {
        parameters['latitude'] = lat;
      }
      if (lng !== null && lng !== undefined) {
        parameters['longitude'] = lng;
      }
      if (addr !== null && addr !== undefined) {
        parameters['address'] = addr;
      }
      if (model !== null && model !== undefined) {
        parameters['shippingModel'] = model;
      }

      if (
        !Object.keys(parameters).includes('address') &&
        (!Object.keys(parameters).includes('latitude') ||
          !Object.keys(parameters).includes('longitude'))
      ) {
        return;
      }

      axios
        .get(`${API_PREFIX}/Api-getNearByStores`, parameters)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getTax = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getCompanynumber`)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const newTax = ({
    uniformNumbers,
    companyName,
    companyVIPnumber,
    isDefault = false,
  }) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-createCompanynumber`, {
          uniformNumbers,
          companyName,
          companyVIPnumber,
          isDefault,
        })
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const updateTax = ({
    uniformNumbers,
    companyName,
    companyVIPnumber,
    isDefault = false,
    ID,
  }) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-updateCompanynumber?ID=${ID}`, {
          uniformNumbers,
          companyName,
          companyVIPnumber,
          isDefault,
        })
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const deleteTax = ({ ID }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-removeCompanynumber`, {
          ID,
        })
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getAddress = () =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${API_PREFIX}/Api-getAddressesForCustomerFromCustomerList?per_page=99&current_page=1`
        )
        .then((response) => {
          // console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const newAddress = ({
    fullName,
    phone,
    address,
    isDefault,
    deliveryIsElevator,
    deliveryPointValue,
    tag,
    deliveryNote,
    defaultStoreId,
    district,
    postalCode_3,
  }) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-createAddressForCustomerInCustomerList`, {
          fullName,
          phone,
          address,
          isDefault,
          deliveryIsElevator,
          deliveryPointValue,
          tag,
          deliveryNote,
          defaultStoreId,
          district,
          postalCode_3,
        })
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const updateAddress = ({
    fullName,
    phone,
    address,
    isDefault,
    deliveryIsElevator,
    deliveryPointValue,
    addressId,
    lastName,
    tag,
    deliveryNote,
    defaultStoreId,
    district,
    postalCode_3,
  }) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${API_PREFIX}/Api-updateAddressForCustomerInCustomerList?addressId=${addressId}`,
          {
            fullName,
            phone,
            address,
            isDefault,
            deliveryIsElevator,
            deliveryPointValue,
            lastName,
            tag,
            deliveryNote,
            defaultStoreId,
            district,
            postalCode_3,
          }
        )
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const deleteAddress = ({ addressId }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-removeAddressForCustomerInCustomerList`, {
          addressId,
        })
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const login = (username, password, deviceId) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${API_PREFIX}/Api-ocaLogin?username=${username}&password=${password}&device_id=${deviceId}`
        )
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCookie = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-refreshSession`)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getHome = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-homepage`)
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCategories = (currentStoreId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getCategoriesV2?storeId=${currentStoreId}`)
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            const parsedCategories = [];

            for (const category of response.data.rows) {
              const parsedSubCategories = [];
              for (const sub of category.sub) {
                parsedSubCategories.push({
                  id: sub.id,
                  name: sub.name,
                  parentCategoryId: sub.parentCategoryId,
                  image: sub.image,
                });
              }
              parsedCategories.push({
                id: category.id,
                name: category.name,
                parentCategoryId: category.parentCategoryId,
                image: category.image,
                sub: parsedSubCategories,
              });
            }
            resolve(parsedCategories);
          } else {
            if (response.code === API_RESPONSE.STORE_CLOSE) {
              alert(response.reason, () => {
                resolve(false);
              });
            } else {
              alert(response.reason);
            }

            // resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getProduct = (productId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getProductDetail?productId=${productId}`)
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const searchProduct = ({
    keyword,
    currentPage,
    perPage,
    storeId,
    visitorId,
  }) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `v1/qc-site/Api-GoogleRetailSearch?q=${keyword}&storeId=${storeId}&page=${currentPage}&per_page=${perPage}&visitorId=${visitorId}`
        )
        .then((response) => {
          console.log('searchProduct response:' + response);
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            const parsedProducts = [];
            const productDatas = {};
            for (const obj of response.data.products) {
              parsedProducts.push({
                id: obj.productId,
                title: obj.productName,
                item_name: obj.item_name,
                image: obj.image /*obj.product.image.absUrl*/,
                promo_price: obj.promo_price,
                price: obj.price,
                qty: 0,
                max: obj.maxOrderQuantity,
                productPromotions: obj.productPromotions,
                main_category: obj.main_category,
                watermark: obj.watermark,
              });
            }
            productDatas['page'] = response.data.page;
            productDatas['per_page'] = response.data.per_page;
            productDatas['total'] = response.data.total;
            productDatas['rows'] = parsedProducts;
            productDatas['attributionToken'] = response.data.attributionToken;

            resolve(productDatas);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCategoriesProducts = ({
    categoryId,
    currentPage,
    perPage,
    storeId,
    customerNo = '',
  }) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${API_PREFIX}/Api-getCategoryProductV2?categoryId=${categoryId}&catalogId&page=${currentPage}&per_page=${perPage}&storeId=${storeId}&customerNo=${customerNo}`
        )
        .then((response) => {
          console.log('getCategoriesProducts response:', response);
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            const parsedProducts = [];
            const productDatas = {};
            for (const obj of response.data.rows) {
              parsedProducts.push({
                id: obj.productId,
                title: obj.productName,
                image: obj.image_path /*obj.product.image.absUrl*/,
                promo_price: obj.promo_price,
                price: obj.price,
                qty: 0,
                max: obj.maxOrderQuantity,
                productPromotions: obj.productPromotions,
                calloutMsg: obj.calloutMsg,
                main_category: obj.main_category,
                item_name: obj.item_name,
                watermark: obj.watermark,
                categoryId: obj.categoryId,
              });
            }
            productDatas['page'] = response.data.page;
            productDatas['per_page'] = response.data.per_page;
            productDatas['total'] = response.data.total;
            productDatas['rows'] = parsedProducts;

            resolve(productDatas);
          } else {
            if (response.code === API_RESPONSE.STORE_CLOSE) {
              alert(response.reason, () => {
                resolve(false);
              });
            } else {
              alert(response.reason);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getOrders = (
    startDate = '2020-01-01',
    endDate = '2020-12-31',
    currentPage = 1,
    perPage = 9999
  ) =>
    new Promise((resolve, reject) => {
      const parameters = {
        startDate: startDate,
        endDate: endDate,
        currentPage: currentPage,
        perPage: perPage,
      };
      axios
        .get('v1/qc/oms/orders/list', parameters)
        .then((response) => {
          // const response2 = orderData();
          console.log(response);
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data.rows);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const sendOrderHelp = ({
    sfcc_transaction_no = '90011913',
    userMsg = 'test',
    category = '問題反映',
    sub_category = '產品品質/價格相關',
    isAll = false,
    product = [],
  }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        sfcc_transaction_no: sfcc_transaction_no,
        userMsg: userMsg,
        category: category,
        sub_category: sub_category,
        isAll: isAll,
        product: product,
      };
      axios
        .post('v1/qc/oms/question', parameters)
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getOrderDetail = (transNo, source) =>
    new Promise((resolve, reject) => {
      const parameters = {
        sfcc_transaction_no: transNo,
        query_source: source,
      };
      axios
        .get('v1/qc/oms/orders/detail', parameters)
        .then((response) => {
          // const response2 = orderData();
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getOrderHelpCategory = () =>
    new Promise((resolve, reject) => {
      axios
        .get('v1/qc/oms/system/options')
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            resolve(response.data.qcatelog);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getReceiveType = () =>
    new Promise((resolve, reject) => {
      axios
        .get('v1/qc/oms/system/options')
        .then((response) => {
          // console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            resolve(response.data.receive_type);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCustomerCancel = () =>
    new Promise((resolve, reject) => {
      axios
        .get('v1/qc/oms/system/options')
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            resolve(response.data.customer_cancel);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  const getCompanyNumbers = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getCompanynumber`)
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            const parsedNumbers = {
              default: [],
              other: [],
            };

            for (const number of response.data.rows) {
              if (number.isDefault) {
                parsedNumbers.default.push({
                  addressId: number.addressId,
                  name: number.lastName,
                  phone: number.phone,
                  address: number.address,
                });
              } else {
                parsedNumbers.other.push({
                  addressId: number.addressId,
                  name: number.lastName,
                  phone: number.phone,
                  address: number.address,
                });
              }
            }
            resolve(parsedNumbers);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getTags = () =>
    new Promise((resolve, reject) => {
      axios
        .get('v1/qc-site/Api-SearchProductKeyword')
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            resolve(response.data.rows);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getStoreTimeSlots = (storeId = '') =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-deliveryTimeSlots`, { storeId })
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return {};
            resolve(response.data);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCities = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getCityList`)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data.rows);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getDistricts = ({ cityId = 35 }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getDistrictList?cityId=${cityId}`)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data.rows);
          } else {
            reject(response.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getMemberProfile = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getMemberProfile`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            if (!res.data) return false;
            resolve(res.data);
          } else {
            alert(res.reason);
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const updateMemberProfile = ({
    twid,
    cityId,
    districtId,
    address,
    email,
    name,
    birthday,
    printflag,
    detailflag,
  }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        twid,
        city: cityId,
        district: districtId,
        address,
        email,
        name,
        birthday,
        printflag,
        detailflag,
      };

      axios
        .post(`${API_PREFIX}/Api-updMemberProfile`, parameters)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const reOrder = (ccOrderNo) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-reOrder`, { ccOrderNo })
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const orderCancle = (ecorder_no, reason) =>
    new Promise((resolve, reject) => {
      const parameters = {
        ecorder_no: ecorder_no,
        reason: reason,
      };
      axios
        .post('v1/qc/oms/order/app/cancel', parameters)
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const makeOrder = (
    {
      storeId = '',
      ecorderPlatform = 1,
      paymentType = 1,
      wallet_pwd = '',
      shippingDate = '',
      shippingPeriod = '',
      shippingTimeslot = '',
      fastDeliveryPeriod = '',
      shippingModel = '',
      receiverName = '',
      receiverPhone = '',
      receiverAddress = '',
      receiverZip = '',
      receiverElevator = '',
      receiverReceive = '',
      receiverAddressRemark = '',
      invoiceType = '',
      invoiceVehicle = '',
      uniformNumbers = '',
      outofstockAction = '',
      ecorderpayment = '',
      creditCardNo = '',
      expireYYMM = '',
      cvsCode = '',
      isRemembered = true,
      products = [],
      clpe_deduct = 0,
      clpe_deduct_amount = 0,
      new_card = false,
      remark = '',
      affiliateParams = {},
      receiverAddressId = '',
    },
    isV2 = false
  ) =>
    new Promise((resolve, reject) => {
      const parameters = {
        storeId,
        ecorderPlatform,
        paymentType,
        wallet_pwd,
        shippingDate,
        shippingPeriod,
        shippingTimeslot,
        fastDeliveryPeriod,
        shippingModel,
        receiverName,
        receiverPhone,
        receiverAddress,
        receiverZip,
        receiverElevator,
        receiverReceive,
        receiverAddressRemark,
        invoiceType,
        invoiceVehicle,
        uniformNumbers,
        outofstockAction,
        ecorderpayment,
        creditCardNo,
        expireYYMM,
        cvsCode,
        isRemembered,
        products,
        clpe_deduct,
        clpe_deduct_amount,
        new_card,
        remark,
        affiliateParams,
        receiverAddressId,
      };
      if (isV2) {
        parameters.isV2 = 'true';
      }
      axios
        .post(`${API_PREFIX}/Api-placeOrder`, parameters)
        .then((response) => {
          // const response2 = orderData();
          resolve(response);
          // if (response.code === API_RESPONSE.SUCCESS) {
          //   console.log('response', response);
          //   resolve(response.data);
          // } else {
          //   alert(response.reason);
          //   resolve(false);
          // }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const coupons = ({ code, isUseCouponCode = false, couponEndDate }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        code,
        isUseCouponCode,
        couponEndDate,
      };
      axios
        .post(`${API_PREFIX}/Api-updBasketCoupon`, parameters)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const removeCoupons = () =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-removeCoupon`)
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const changeStore = ({ storeId = '' }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        storeId,
      };
      axios
        .post(`${API_PREFIX}/Api-updStoreId`, parameters)
        .then((response) => {
          console.log('response:', response);
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getSuggestions = ({ searchword = '', visitorId = 'anonymous' }) =>
    new Promise((resolve, reject) => {
      axios
        .get('v1/qc-site/Api-SearchProductAutoComplete', {
          searchword,
          visitorId,
        })
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getBonusPoint = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-queryClpePoint`)
        .then((response) => {
          console.log('response', response);
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const sendValidCode = ({ mobile = '' }) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-ocaSendCode`, {
          mobile,
          checkStatusByOms: true,
          ocaToken: axios._oca_token ?? '',
        })
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const validCode = ({ mobile = '', code = '' }) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-ocaVerifyCode`, { mobile, code })
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const walletCodeValid = ({ pwd = '', skey = '' }) =>
    new Promise((resolve, reject) => {
      axios
        .post('v1/qc/oms/edenred/pwd/confirm', { pwd, skey })
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCarrefourWallet = ({ shop_id, account_id, expired_tag = 0 }) =>
    new Promise((resolve, reject) => {
      const barcodeKey = process.env.GATSBY_BARCODE_KEY;
      axios
        .post(
          'v1/qc/wallet/balance',
          {
            shop_id,
            account_id,
            expired_tag,
          },
          {
            headers: {
              apikey: barcodeKey,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const checkStock = (storeId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-checkBasket`, { storeId })
        .then((response) => {
          console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            if (response.code === API_RESPONSE.STORE_CLOSE) {
              alert(response.reason, () => {
                resolve({
                  type: 'closeStore',
                });
              });
              return;
            }
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const checkMobileCode = ({ barcode = '' }) =>
    new Promise((resolve, reject) => {
      const parameters = {
        barcode,
      };
      console.log('request', barcode);
      const barcodeKey = process.env.GATSBY_BARCODE_KEY;
      axios
        .post('v1/qc/oca/postCheckBarcode', parameters, {
          headers: {
            apikey: barcodeKey,
          },
        })
        .then((response) => {
          console.log('response:', response);
          if (response.result) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getSkey = ({ accessToken }) =>
    new Promise((resolve, reject) => {
      const barcodeKey = process.env.GATSBY_BARCODE_KEY;
      axios
        .get(
          'v1/qc/oca/getskey',
          {
            token: accessToken,
          },
          {
            headers: {
              apiKey: barcodeKey,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const setShippingMethod = () =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-updShippingMethod`, { id: '1' })
        .then((response) => {
          // console.log('response:' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const deleteMember = ({ accessToken }) =>
    new Promise((resolve, reject) => {
      //resolve(true);
      axios
        .get(`${API_PREFIX}/Api-cancelAccount`, {
          access_token: accessToken,
        })
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason);
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  // const deleteMember = ({ access_token = '' }) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post('v1/qc/sfcc/member/cancellation', { access_token })
  //       .then((response) => {
  //         if (response.code === API_RESPONSE.SUCCESS) {
  //           resolve(response.data);
  //         } else {
  //           alert(response.reason);
  //           resolve(false);
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });

  const getAppInfo = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-appInfo`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            alert(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addrStandardization = (query) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getAddrStandardization`, { query })
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data.results);
          } else {
            alert('請輸入完整地址');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const textSearch = ({ query, location }) => {
    if (location === '' || location === undefined) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_PREFIX}/Api-getAddrTextSearch`, { query })
          .then((res) => {
            if (res.code === API_RESPONSE.SUCCESS) {
              resolve(res.data.results);
            } else {
              // alert(res.reason);
              console.log(res.reason);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_PREFIX}/Api-getAddrTextSearch`, { query, location })
          .then((res) => {
            if (res.code === API_RESPONSE.SUCCESS) {
              resolve(res.data.results);
            } else {
              // alert(res.reason);
              console.log(res.reason);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  };

  const getGpsLocation = (latlng) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-geocoding`, { latlng })
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data.results);
          } else {
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getAddressDetail = (addressId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getAddressesForCustomerFromCustomerDetail`, {
          addressId,
        })
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            console.log('error', response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addressSearch = (address) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getAddrAutocomplete`, { ...address })
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data.predictions);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCartListNoneCheck = (param) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getBasketA`, param)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            if (response.code === API_RESPONSE.STORE_CLOSE) {
              alert(response.reason, () => {
                resolve(false);
              });
              return;
            } else {
              alert(response.reason);
            }
            //alert response.reason
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCartListFinalStep = (param) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-getBasketB`, param)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            if (response.code === API_RESPONSE.STORE_CLOSE) {
              alert(response.reason, () => {
                resolve(false);
              });
              return;
            } else {
              alert(response.reason);
            }
            //alert response.reason
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getCartCheckQty = (parameters) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-checkBasketProduct`, parameters)
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            if (!response.data) return [];
            resolve(response.data);
          } else {
            if (response.code === API_RESPONSE.STORE_CLOSE) {
              alert(response.reason, () => {
                resolve(false);
              });
              return;
            } else {
              alert(response.reason);
            }
            //alert response.reason
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getRecipeList = (query) => {
    const barcodeKey = process.env.GATSBY_BARCODE_KEY;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `v1/qc/gpt/get_recipes`,
          { ...query },
          { headers: { apikey: barcodeKey } }
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  };

  const getRecipeInfo = (query) => {
    const barcodeKey = process.env.GATSBY_BARCODE_KEY;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `v1/qc/gpt/get_recipe_detail?store_code=${query.storeId}&id=${query.id}`,
          {},
          {
            headers: {
              apikey: barcodeKey,
            },
          }
        )
        .then((res) => {
          resolve(res.data.bean);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const addToCartBatch = ({ storeId, productlist }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_PREFIX}/Api-batchUpdItemsInBasket`, {
          storeId,
          productlist,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const fetchRecipeData = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`v2/qc/gpt/get_qryinfo`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const removeCreditCard = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Api-RemoveFastPayment`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getAddtionItems = (parameters) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/qc-site/Api-addtionalItems`, parameters)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getMyCouponList = (status = '1') => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Coupon-myCoupons?status=${status}`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getUsageList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Coupon-couponInfo`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCouponRule = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Coupon-content?contentassetId=qc-lawOfCouponCenter`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCouponList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Coupon-couponList`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const receiveCoupon = ({ couponId = '' }) => {
    return new Promise((resolve, reject) => {
      const parameters = {
        couponId,
      };
      axios
        .post(`${API_PREFIX}/Coupon-getCouponTicket`, parameters)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCartCoupon = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/Coupon-validCoupons`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res.reason);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllMyRecipes = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/qc/gpt/myrecipes`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res);
          }
        })
        .catch((err) => reject(err));
    });
  };

  const fetchMyRecipes = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/qc/gpt/myrecipes`, { ...params })
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res);
          }
        })
        .catch((err) => reject(err));
    });
  };

  const addMyRecipe = (recipeId) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/qc/gpt/myrecipes/${recipeId}`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res);
          } else {
            console.log(res);
          }
        })
        .catch((err) => reject(err));
    });
  };

  const removeMyRecipe = (recipeId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/qc/gpt/myrecipes/${recipeId}`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res);
          } else {
            console.log(res);
          }
        })
        .catch((err) => reject(err));
    });
  };

  const fetchAIRecipeSettings = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/qc/oms/airecipe/settings`)
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res);
          }
        })
        .catch((err) => reject(err));
    });
  };

  const sendAIRecipeMessage = (params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`v1/qc/oms/airecipe/ai/message`, { ...params })
        .then((res) => {
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          } else {
            console.log(res);
          }
        })
        .catch((err) => reject(err));
    });
  };

  const getRecommendItems = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/qc-site/Api-getRecommendItem`)
        .then((res) => {
          console.log('getRecommendItems:', res);
          if (res.code === API_RESPONSE.SUCCESS) {
            resolve(res.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateRecommendItems = (parameters) =>
    new Promise((resolve, reject) => {
      axios
        .post('v1/qc-site/Api-updItemInBasket', parameters)
        .then((response) => {
          console.log('response: ' + JSON.stringify(response));
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.reason, () => {
              resolve(false);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const walletSendOtp = (parameters) =>
    new Promise((resolve, reject) => {
      axios
        .post('v1/qc/oms/cortex/app/wallet/sendCode', parameters)
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.msg, () => {
              resolve(false);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const walletCheckPassword = (parameters) =>
    new Promise((resolve, reject) => {
      axios
        .post('v1/qc/oms/cortex/app/wallet/check/password', parameters)
        .then((response) => {
          if (response.code === API_RESPONSE.SUCCESS) {
            resolve(response.data);
          } else {
            alert(response.msg, () => {
              resolve(false);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  return {
    axios,
    getInfo,
    getOrders,
    getAddress,
    updateAddress,
    getStoreByLocation,
    login,
    updateToken,
    getCategories,
    getCartList,
    addCart,
    removeCart,
    updateCart,
    getSampleGet,
    getSamplePost,
    getRefoundList,
    getProduct,
    getRefundDetail,
    searchProduct,
    getCategoriesProducts,
    getOrderDetail,
    getOrderHelpCategory,
    sendOrderHelp,
    getCompanyNumbers,
    getTags,
    getStoreTimeSlots,
    getMemberProfile,
    updateMemberProfile,
    reOrder,
    getCities,
    getDistricts,
    makeOrder,
    getCookie,
    coupons,
    getTax,
    newTax,
    updateTax,
    deleteTax,
    getSuggestions,
    getBonusPoint,
    newAddress,
    deleteAddress,
    getReceiveType,
    sendValidCode,
    validCode,
    changeStore,
    removeCoupons,
    walletCodeValid,
    getCarrefourWallet,
    checkStock,
    checkMobileCode,
    getSkey,
    setShippingMethod,
    deleteMember,
    getAppInfo,
    getCustomerCancel,
    orderCancle,
    addrStandardization,
    textSearch,
    getGpsLocation,
    getAddressDetail,
    addressSearch,
    getCartListNoneCheck,
    getCartCheckQty,
    getCartListFinalStep,
    getRecipeList,
    getRecipeInfo,
    addToCartBatch,
    fetchRecipeData,
    removeCreditCard,
    getAddtionItems,
    getMyCouponList,
    getUsageList,
    getCouponRule,
    getCouponList,
    receiveCoupon,
    getCartCoupon,
    fetchAllMyRecipes,
    fetchMyRecipes,
    addMyRecipe,
    removeMyRecipe,
    fetchAIRecipeSettings,
    sendAIRecipeMessage,
    getRecommendItems,
    updateRecommendItems,
    walletSendOtp,
    walletCheckPassword,
  };
};

export { useAPIMethods };

export { default as Axios } from './_axios';
